import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useQueryCache } from "react-query";
import { Box, Button, Flex, Spinner } from "theme-ui";
import { getProductList } from "../../../apis";
import {
  defaultCurrentPage,
  productsTableViewMinWidth,
  refetchIntervalValues,
} from "../../../constants";
import { useModal } from "../../../contexts/ModalContext";
import { useTradeInCartContext } from "../../../contexts/TradeInCartContext";
import themes from "../../../themes";
import {
  generateRandomComplexValue,
  getWeekdaysAfterDate,
  isDeliveryDateAfterWeekdays,
  tradeInInputValueCheck,
} from "../../../utils/helpers";
import ConfirmationModal from "../../ConfirmationModal";
import WithLoader from "../../WithLoader";
import IconClose from "../../icons/icon-close";
import IconShoppingCart from "../../icons/icon-shopping-cart";
import IconTrash from "../../icons/icon-trash";
import AddProductCard from "./AddProductCard";
import MobileProductCart from "./MobileProductCart";
import MobileProductsTable from "./MobileProductsTable";
import ProductsTable from "./ProductsTable";
import TradeInSearchBar from "../TradeInSearchBar";
import DirectBuyCheckModal from "../modals/DirectBuyCheckModal";
import ProductCreateModal from "../modals/ProductCreateModal";
import TradeINHelpModal from "../modals/TradeINHelpModal";

const StepThreeCartContainer = styled.div`
  width: 30em;
  height:100%,
  margin: 0.3em 0.3em 1em 0;
  padding: 0.7em;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.colors.searchBorderBG};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1270px) {
    display: none;
    width:100%
  }
`;
const SearchTitle = styled.div`
  width: 100%;
  height: 2.2em;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.8rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};
`;
const TradeINProducts = (props) => {
  const {
    nextStep,
    formValues,
    cartProducts,
    setCartProducts,
    handleStepAlertModal,
    errProducts,
  } = props;
  const {
    isOpen,
    changeProductCount,
    enableTradeInCart,
    disableTradeInCart,
  } = useTradeInCartContext();
  const { t } = useTranslation();
  const [focusId, setFocusId] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [randomArticleNo, setRandomArticleNo] = useState(null);
  const [showCreateProductModal, setShowCreateProductModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showDirectBuyCheckModal, setShowDirectBuyCheckModal] = useState(false);
  const [handleValues, setHandleValues] = useState([]);
  const [errorInputs, setErrorInputs] = useState([]);
  const [searchText, setSearchText] = useState();
  const { showModal } = useModal();
  const [isDirectBuy, setIsDirectBuy] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const windowHeight = useMemo(() => window.innerHeight, []);
  const [page, setPage] = useState(defaultCurrentPage);
  const [previousRowStopIndex, setPreviousRowStopIndex] = useState(-1);

  useEffect(() => {
    if (window) {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [setWindowWidth]);

  const {
    data,
    isLoading,
    canFetchMore,
    fetchMore,
    isFetching,
  } = useInfiniteQuery(
    ["get-product-list", searchText, isDirectBuy],
    getProductList,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: refetchIntervalValues?.stockListValue,
      getFetchMore: (response) => {
        setPage(
          response.meta.last_page > response.meta.current_page
            ? response.meta.current_page + 1
            : page
        );
        return response.meta.last_page > response.meta.current_page
          ? response.meta.current_page + 1
          : null;
      },
    }
  );

  const queryCache = useQueryCache();

  const handleAddToCart = useCallback(
    (key, item, values) => {
      if (
        values &&
        values[item?.article_no] &&
        values[item?.article_no]?.unit_price &&
        values[item?.article_no]?.min_order_quantity &&
        values[item?.article_no]?.delivery_date
      ) {
        setFocusId(null);
        const newItem = values[item?.article_no];
        let newCartItem = {
          article_no: item?.article_no,
          article_name: item?.description,
          min_order_quantity: newItem?.min_order_quantity,
          unit_price: newItem?.unit_price || item?.direct_buy_price,
          delivery_date: newItem?.delivery_date,
          european_article_number:
            item?.ean || newItem?.european_article_number,
          direct_buy_price: item?.direct_buy_price,
          is_direct_buy: item?.direct_buy_price > 0,
          new_product: false,
          quantity_set: item?.quantity_set,
        };

        if (
          newItem?.take_all_quantity > newItem?.min_order_quantity &&
          newItem?.min_order_quantity === item?.quantity_set
        ) {
          newCartItem = {
            ...newCartItem,
            take_all_quantity: newItem?.take_all_quantity,
            take_all_unit_price: newItem?.take_all_unit_price,
          };
        }

        if (
          item?.direct_buy_price > 0 &&
          (newCartItem?.unit_price >= item.direct_buy_price ||
            !isDeliveryDateAfterWeekdays(newCartItem, getWeekdaysAfterDate()))
        ) {
          setModalData(newCartItem);
          setShowDirectBuyCheckModal(true);
        }

        const checkCartProduct = cartProducts.filter(
          (cartItem) => cartItem?.article_no === item?.article_no
        );
        if (!(checkCartProduct.length > 0)) {
          setCartProducts((prev) => [...prev, newCartItem]);
        }
      }
    },
    [cartProducts, setCartProducts]
  );

  const handleChange = useCallback(
    (id, name, value, item) => {
      let error = false;
      switch (name) {
        case "min_order_quantity":
          error = tradeInInputValueCheck(
            setErrorInputs,
            id,
            value,
            item?.quantity_set
          );

          break;
        case "take_all_quantity":
          error = tradeInInputValueCheck(
            setErrorInputs,
            id,
            item?.quantity_set,
            value
          );

          break;
        case "unit_price":
          error =
            item?.direct_buy_price > -1
              ? tradeInInputValueCheck(
                  setErrorInputs,
                  id,
                  value,
                  item?.direct_buy_price + 10
                )
              : tradeInInputValueCheck(setErrorInputs, id, value, 99999);

          break;
        default:
          break;
      }

      if (error) {
        setErrorInputs((prevErrors) => {
          return { ...prevErrors, [id]: true };
        });
      }

      setHandleValues((prevItems) => {
        const existingItem = {
          ...prevItems[item?.article_no],
        };
        existingItem[name] = value;
        return { ...prevItems, [item?.article_no]: existingItem };
      });
    },
    [setHandleValues, setErrorInputs]
  );

  const handleRemove = useCallback(
    (item) =>
      showModal(({ hideModal }) => {
        return (
          <ConfirmationModal
            title={t("step_three_delete_alert_modal_title")}
            titleStyle={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              textAlign: "left !importan",
              backgroundColor: themes.colors.tableBgGray,
              width: "100%",
              marginBottom: "1.5em",
            }}
            question={t("step_three_delete_alert_modal_description")}
            negativeButton={{
              style: {
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                textTransform: "capitalize",
                borderRadius: "3px",
                width: "7em",
                padding: "0.5em",
                alignItems: "center",
                verticalAlign: "middle",
              },
              icon: (
                <IconTrash
                  fill={themes.colors.white}
                  width="1em"
                  height="auto"
                />
              ),
              content: t("delete"),
              action: () => {
                const updatedItems = cartProducts.filter(
                  (cartItem) => cartItem?.article_no !== item?.article_no
                );
                setCartProducts(updatedItems);
                hideModal();
              },
            }}
            positiveButton={{
              content: t("cancel"),
              style: {
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                textTransform: "capitalize",
                borderRadius: "3px",
                width: "7em",
                padding: "0.5em",
                alignItems: "center",
                verticalAlign: "middle",
                marginRight: "1em",
              },
              icon: (
                <IconClose
                  fill={themes.colors.white}
                  width="1.2em"
                  height="auto"
                />
              ),
              action: () => {
                hideModal();
              },
            }}
          />
        );
      }),
    [cartProducts, setCartProducts, showModal, t]
  );

  const onModalOpen = useCallback(
    (values) => {
      if (values) {
        setModalData(values);
      } else {
        setRandomArticleNo(generateRandomComplexValue);
        setModalData(null);
      }
      setShowCreateProductModal(true);
    },
    [setModalData, setShowCreateProductModal]
  );

  useEffect(() => {
    enableTradeInCart();
    return disableTradeInCart;
  }, [enableTradeInCart, disableTradeInCart]);

  useEffect(() => changeProductCount(cartProducts?.length), [
    cartProducts?.length,
    changeProductCount,
  ]);

  const combineStockData = useCallback((data) => {
    if (data?.length) return data.flatMap((item) => item?.data || []);
    else return [];
  }, []);
  const stockList = useMemo(() => combineStockData(data), [data]);

  const handleNextStep = useCallback(() => {
    if (cartProducts?.length > 0) {
      nextStep();
    } else handleStepAlertModal(t("step_three_next_step_error_message"));
  }, [handleStepAlertModal, cartProducts?.length, nextStep, t]);

  const handleScroll = useCallback(
    (event) => {
      const { rowOverscanStopIndex, rowStopIndex } = event;
      if (previousRowStopIndex === -1) {
        setPreviousRowStopIndex(rowStopIndex);
        fetchMore();
      } else if (
        previousRowStopIndex + 1 < rowStopIndex &&
        rowOverscanStopIndex === rowStopIndex &&
        canFetchMore
      ) {
        setPreviousRowStopIndex(rowStopIndex);
        fetchMore();
      }
    },
    [previousRowStopIndex, canFetchMore, fetchMore, setPreviousRowStopIndex]
  );

  return (
    <>
      {isOpen && (
        <MobileProductCart
          cartProducts={cartProducts}
          onModalOpen={onModalOpen}
          handleNextStep={handleNextStep}
          handleRemove={handleRemove}
          errProducts={errProducts}
          setCartProducts={setCartProducts}
          handleStepAlertModal={handleStepAlertModal}
          windowHeight={windowHeight}
          windowWidth={windowWidth}
        />
      )}

      <Flex
        sx={{
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Flex sx={{ width: "100%" }}>
          <TradeInSearchBar
            setSearchText={(text) => {
              setSearchText(text);
              queryCache.invalidateQueries([
                "get-product-list",
                searchText,
                isDirectBuy,
              ]);
            }}
            setIsDirectBuy={setIsDirectBuy}
            searchText={searchText}
            onModalOpen={onModalOpen}
            setModalData={setModalData}
            setShowHelpModal={setShowHelpModal}
            setPreviousRowStopIndex={setPreviousRowStopIndex}
          />
        </Flex>
        {isLoading ? (
          <Flex
            sx={{
              width: "100%",
              height: "100vh",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <WithLoader isLoading />
          </Flex>
        ) : (
          <Flex
            sx={{
              width: `calc(${windowWidth}px - 1.3em)`,
              height: `calc(${windowHeight}px - 10em)`,
              flexDirection: "row",
              "@media screen and (max-width:270px)": {
                width: `${windowWidth}px`,
              },
            }}
          >
            <Flex
              sx={{
                width: `calc(${windowWidth}px - 28em)`,
                margin: "0 1em",
                "@media screen and (max-width:1270px)": {
                  width: `${windowWidth}px`,
                },
                "@media screen and (max-width:767px)": {
                  width: "98%",
                  flexDirection: "column",
                  justifyContent: "center",
                },
              }}
            >
              {windowWidth > productsTableViewMinWidth ? (
                <ProductsTable
                  isLoading={isLoading}
                  stockList={stockList}
                  onScroll={handleScroll}
                  handleValues={handleValues}
                  handleAddToCart={handleAddToCart}
                  handleChange={handleChange}
                  cartProducts={cartProducts}
                  canFetchMore={canFetchMore}
                  isFetching={isFetching}
                  page={page}
                  errorInputs={errorInputs}
                  setErrorInputs={setErrorInputs}
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                  focusId={focusId}
                  setFocusId={setFocusId}
                />
              ) : (
                <MobileProductsTable
                  isLoading={isFetching || isLoading}
                  stockList={stockList}
                  onScroll={handleScroll}
                  handleValues={handleValues}
                  handleAddToCart={handleAddToCart}
                  handleChange={handleChange}
                  cartProducts={cartProducts}
                  canFetchMore={canFetchMore}
                  fetchMore={fetchMore}
                  page={page}
                  isFetching={isFetching}
                  errorInputs={errorInputs}
                  setErrorInputs={setErrorInputs}
                  focusId={focusId}
                  setFocusId={setFocusId}
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                />
              )}
            </Flex>

            <Flex
              sx={{
                justifyContent: "flex-end",
                width: "28em",
                height: "100%",
                marginRight: "0.5em",
                "@media screen and (max-width:1270px)": {
                  width: "0",
                },
              }}
            >
              <StepThreeCartContainer>
                <SearchTitle>
                  <Box sx={{ marginRight: "1rem" }}>
                    <IconShoppingCart />
                  </Box>
                  {`${t("step_three_product_cart_title")} ( ${
                    cartProducts?.length || 0
                  } )`}
                </SearchTitle>
                <Box
                  sx={{
                    height: "100%",
                    overflow: "auto",

                    "@media screen and (min-height: 60em)": {
                      height: "100%",
                    },
                  }}
                >
                  <AddProductCard
                    data={cartProducts}
                    errProducts={errProducts}
                    setData={setCartProducts}
                    onModalOpen={onModalOpen}
                    handleStepAlertModal={handleStepAlertModal}
                    nextStep={nextStep}
                    handleNextStep={handleNextStep}
                    handleRemove={handleRemove}
                    windowWidth={windowWidth}
                  />
                </Box>
                <Flex
                  style={{
                    minWidth: "3rem",
                    display: "flex",
                    flexDirection: ["row", "row", "row", "column"],
                    justifyContent: "flex-end",
                    margin: "1rem 1rem 0rem 1rem",
                    padding: "1em 0rem 0.5rem 0",
                    borderTop: `1px solid ${themes.colors.primary}`,
                  }}
                >
                  <Button
                    type="button"
                    onClick={handleNextStep}
                    sx={{
                      minWidth: "3rem",
                      borderRadius: "3px",
                    }}
                  >
                    {t("step_next")}
                  </Button>
                </Flex>
              </StepThreeCartContainer>
            </Flex>
          </Flex>
        )}
        <Flex
          sx={{
            width: "75%",
            height: "60px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "@media screen and (max-width: 1271px)": {
              width: "100%",
            },
          }}
        >
          {!isLoading && isFetching && <Spinner size="2rem" />}
        </Flex>
      </Flex>

      <ProductCreateModal
        showState={showCreateProductModal}
        setShowState={setShowCreateProductModal}
        cartProducts={cartProducts}
        setCartProducts={setCartProducts}
        formValues={formValues}
        data={modalData}
        setData={setModalData}
        randomArticleNo={randomArticleNo}
        setRandomArticleNo={setRandomArticleNo}
      />
      <DirectBuyCheckModal
        showState={showDirectBuyCheckModal}
        setShowState={setShowDirectBuyCheckModal}
        data={modalData}
        setData={setModalData}
        cartProducts={cartProducts}
        setCartProducts={setCartProducts}
      />
      <TradeINHelpModal
        showState={showHelpModal}
        setShowState={setShowHelpModal}
      />
    </>
  );
};

TradeINProducts.propTypes = {
  nextStep: PropTypes.func,
  formValues: PropTypes.object,
  cartProducts: PropTypes.object,
  setCartProducts: PropTypes.func,
  handleStepAlertModal: PropTypes.func,
  errProducts: PropTypes.object,
};

export default TradeINProducts;
