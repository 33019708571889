import React, { useCallback, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Text } from "theme-ui";
import {
  API_REQUEST_STATUS,
  offerUsModalInitialValues,
  QTY,
  submitStatus,
  validCurreny,
} from "../../../constants";
import themes from "../../../themes";
import RFFInput from "../../form-fields/rff-fields/RFFInput";
import IconClose from "../../icons/icon-close";
import {
  calculateMinDate,
  getEuropeanDateFormat,
  calculateNextDate,
} from "../../../utils/helpers";
import RFFDayPicker from "../../form-fields/rff-fields/RFFDayPicker";
import { useMutation } from "react-query";
import { tradeInOrderOfferChange } from "../../../apis";
import { useToast } from "../../../contexts/AlertProvider";
import { tradeInOrderOfferChangeValidate } from "../../../utils/validators";

const OfferUsModal = ({
  setShowOfferModal,
  selectedItem,
  isLoading,
  statusValue,
  refetch,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const tomorrow = useMemo(() => calculateNextDate(), []);
  const [switchValue, setSwitchValue] = useState(
    selectedItem?.latest_counter_offer?.has_commission || false
  );
  const [mutateOffer, { isLoading: isLoadingOffer }] = useMutation(
    tradeInOrderOfferChange
  );

  const onSubmit = useCallback(
    async (values) => {
      try {
        const response = await mutateOffer({
          ...values,
          has_commission: switchValue,
          status: statusValue,
          tradein_order_item_id: selectedItem?.id,
        });
        if (response.status === API_REQUEST_STATUS.OK) {
          toast(t("offer_successfully"), {
            type: submitStatus.SUCCESS,
          });
          setShowOfferModal(false);
          refetch();
        } else {
          toast(t("offer_error"), {
            type: submitStatus.ERROR,
          });
        }
      } catch {
        toast(t("offer_error"), {
          type: submitStatus.ERROR,
        });
      }
    },
    [mutateOffer, setShowOfferModal, t, toast, switchValue]
  );

  const validate = useCallback(
    (values) => tradeInOrderOfferChangeValidate(values, t("field_required")),
    [t]
  );

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: themes.colors.opaqueBlack,
        zIndex: 100,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: themes.colors.white,
          borderRadius: "4px",
          width: ["90%", "80%", "60%", "40%"],
          overflow: "auto",
          zIndex: 100,
        }}
      >
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "70px",
            backgroundColor: themes.colors.primary,
            borderBottom: `1px solid ${themes.colors.darkGray}`,
            padding: "20px",
          }}
        >
          <Text
            sx={{
              fontWeight: "bold",
              fontSize: 18,
              color: themes.colors.white,
            }}
          >
            {t("offer_us")} /
            {`${getEuropeanDateFormat(selectedItem?.created_at)}`}
          </Text>

          <Box
            sx={{
              padding: 0,
              margin: 0,
              width: "20px",
              height: "20px",
              cursor: "pointer",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: themes.colors.white,
            }}
            onClick={() => setShowOfferModal(false)}
          >
            <IconClose width="100%" height="100%" fill={themes.colors.black} />
          </Box>
        </Flex>
        <Form
          initialValues={offerUsModalInitialValues(tomorrow)}
          validate={validate}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "20px",
                height: "calc( 100% - 70px )",
              }}
              onSubmit={handleSubmit}
            >
              <Flex
                sx={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "20px",
                }}
              >
                <RFFInput
                  type={"text"}
                  name={"article_name"}
                  label={t("product_description")}
                  placeholder={selectedItem?.article_name}
                  disabled={selectedItem?.article_name}
                  sx={{
                    backgroundColor: themes.colors.tableLightHeaderGray,
                    color: themes.colors.tableHeaderTextGray,
                    borderRadius: "2px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", "row"],
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <RFFInput
                    type={"text"}
                    name={"unit_price"}
                    label={t("unit_price")}
                    placeholder={selectedItem?.unit_price}
                    disabled={selectedItem?.unit_price}
                    currency={validCurreny}
                    sx={{
                      backgroundColor: themes.colors.tableLightHeaderGray,
                      color: themes.colors.tableHeaderTextGray,
                      borderRadius: "2px",
                    }}
                  />
                  <RFFInput
                    type={"number"}
                    name={"price"}
                    label={t("unit_price")}
                    max={99999}
                    currency={validCurreny}
                    sx={{
                      borderRadius: "2px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", "row"],
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <RFFInput
                    type={"number"}
                    name={"minimum_order_quantity"}
                    label={t("minimum_order_quantity")}
                    placeholder={selectedItem?.min_order_quantity}
                    disabled={selectedItem?.min_order_quantity}
                    currency={QTY}
                    sx={{
                      backgroundColor: themes.colors.tableLightHeaderGray,
                      color: themes.colors.tableHeaderTextGray,
                      borderRadius: "2px",
                    }}
                  />
                  <RFFInput
                    type={"number"}
                    name={"quantity"}
                    label={t("minimum_order_quantity")}
                    max={99999}
                    currency={QTY}
                    sx={{
                      borderRadius: "2px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", "row"],
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <RFFDayPicker
                    name={"delivery_date"}
                    label={t("delivery_date")}
                    minimumDate={calculateMinDate()}
                    defaultValue={
                      offerUsModalInitialValues(tomorrow)?.delivery_date
                    }
                  />
                  <RFFInput
                    type={"bool"}
                    name={"has_commission"}
                    placeholder={t("has_commission")}
                    disabled={true}
                    isSwitch={true}
                    switchValue={switchValue}
                    setSwitchValue={setSwitchValue}
                    sx={{
                      backgroundColor: themes.colors.tableLightHeaderGray,
                      color: themes.colors.black,
                      borderRadius: "2px",
                      marginTop: "26px",
                    }}
                  />
                </Box>
              </Flex>
              <Flex
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                  padding: ["20px", "40px"],
                  flexDirection: "row",
                }}
              >
                <Button
                  sx={{
                    width: "50%",
                    height: "90%",
                    borderRadius: "4px",
                    backgroundColor: themes.colors.grey,
                    color: themes.colors.black,
                    textTransform: "capitalize",
                    "&:hover": {
                      color: themes.colors.white,
                    },
                  }}
                  onClick={() => setShowOfferModal(false)}
                >
                  {t("cancel")}
                </Button>
                <Button
                  sx={{
                    width: "50%",
                    height: "90%",
                    borderRadius: "4px",
                    backgroundColor: themes.colors.primary,
                    color: themes.colors.white,
                    textTransform: "capitalize",
                  }}
                  type="submit"
                  isLoading={isLoadingOffer && isLoading}
                >
                  {t("create")}
                </Button>
              </Flex>
            </form>
          )}
        />
      </Box>
    </Box>
  );
};

export default OfferUsModal;
