/** @jsx jsx*/
import styled from "@emotion/styled";
import { Box, Flex, jsx } from "theme-ui";
import PropTypes from "prop-types";
import IconCheveronDown from "./icons/icon-cheveron-down";
import themes from "../themes";
import { Fragment } from "react";
const DropdownContent = styled(Box)`
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #f9f9f9;
  box-shadow: ${(props) => props.theme.shadows.dropDown};
  z-index: 100;
  min-width: 11rem;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 0;
  border-radius: 0.125rem;
`;

const Dropdown = styled(Box)`
  position: relative;
  display: block;
  height: 2rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  .dropdown-icon {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    fill: ${(props) => props.rightIconColor || "inherit"};
  }

  &:hover > .dropdown-content {
    display: block;
  }

  .dropdown-label-block {
    cursor: pointer;
    font-size: 1rem;
    align-items: center;
  }
`;

const MenuItem = styled(Flex)`
  padding: 0.5rem 1rem;
  align-items: center;
  justify-content: start;
  font-weight: 400;

  &:hover {
    background-color: #eeeeee;
  }
`;

const Menu = ({
  label,
  items = [],
  contentStyle = {},
  Icon,
  borderColor = null,
  showDropdownIcon = true,
  className,
  rightIconColor = null,
  textColor = null,
  customLabel,
}) => {
  return (
    <Dropdown
      className={`dropdown-menu ${className ? className : null}`}
      rightIconColor={rightIconColor}
      sx={
        showDropdownIcon
          ? {
              paddingRight: "0.75rem",
              border: borderColor ? `1px solid ${borderColor}` : null,
              borderRadius: "10px",
            }
          : null
      }
    >
      <Flex className="dropdown-label-block" px="1">
        {Icon && <Icon fill={themes.colors.white} />}
        {customLabel ? (
          customLabel
        ) : (
          <label style={{ marginLeft: "2px" }}>{label}</label>
        )}
        {showDropdownIcon ? (
          <IconCheveronDown className="dropdown-icon" />
        ) : null}
      </Flex>
      {items.length > 0 && (
        <DropdownContent className="dropdown-content" sx={contentStyle}>
          {items.map(({ label, onClick, isActive = false }, index) => {
            return (
              <MenuItem
                className={`menu-item ${isActive ? "active" : ""}`}
                onClick={onClick}
                key={index}
              >
                <label
                  style={{
                    marginLeft: "2px",
                    color: textColor,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {label}
                </label>
              </MenuItem>
            );
          })}
        </DropdownContent>
      )}
    </Dropdown>
  );
};

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      isActive: PropTypes.bool,
    })
  ).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  contentStyle: PropTypes.object,
  icon: PropTypes.elementType,
  showDropdownIcon: PropTypes.bool,
  className: PropTypes.string,
  Icon: PropTypes.func,
  borderColor: PropTypes.string,
  rightIconColor: PropTypes.string,
  textColor: PropTypes.object,
};

export default Menu;
