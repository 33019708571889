export const nonAxiosError =
  "Something went wrong, the given data may be incorrect or may be a server error. Please try again later or contact us";

export const serverErrorsToErrorMessage = (errors) => {
  let errorMessage = "";
  for (let key in errors) {
    for (let error of errors[key]) {
      errorMessage = errorMessage + (errorMessage !== "" ? " & " : "") + error;
    }
  }

  return errorMessage;
};

const exceptionHandler = (e, onError, onFormError) => {
  if (typeof e === "string") {
    return onError(e);
  }
  if (e?.response) {
    if (e.response.status === 500) {
      return onError(nonAxiosError);
    }
    if (e.response.status === 503) {
      return onError(nonAxiosError);
    }

    if (e?.response?.data) {
      const response = e.response.data;
      if ("errors" in response) {
        if (onFormError) return onFormError(response.errors);

        return onError(serverErrorsToErrorMessage(response.errors));
      } else {
        return onError(response.message || response.msg || response.error);
      }
    }
  } else {
    return onError(e.message);
  }
};

export default exceptionHandler;
