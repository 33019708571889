import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Image, Text } from "theme-ui";
import IconEdit from "../../../components/icons/icon-edit";
import IconImage from "../../../components/icons/icon-image";
import IconPlus from "../../../components/icons/icon-plus";
import IconClose from "../../../components/icons/icon-close";
import Money from "../../../components/Money";
import PropTypes from "prop-types";
import TableCounterInput from "./TableCounterInput";
import {
  addDefaultSource,
  getFormatedYukaPoints,
} from "../../../utils/helpers";
import YPImage from "../../../assets/images/YP.png";
import { useModal } from "../../../contexts/ModalContext";
import ProductImageModal from "../../../components/ProductImageModal";
import { useCOAStatus } from "../../../contexts/COAProvider";
import ReactTooltip from "react-tooltip";
import IconPhone from "../../../components/icons/icon-phone";
import { useTranslation } from "react-i18next";
import themes from "../../../themes";
import { PRODUCT_STOCK_STATUS, TELE_BESTELLEN } from "../../../constants";

const ShopTableRow = ({
  data: {
    article_no,
    ean,
    part_number,
    description,
    selling_price,
    reward_points,
    max_quantity,
    next_delivery_date,
    stock_status,
    is_added_to_cart = false,
    cart_quantity,
    image,
    actual_stock,
    purchased_stock,
    tele_bestellen,
    max_menge
  },
  onAddToCart,
  onUpdateToCart,
  csell,
}) => {
  const isCOAEnabled = useCOAStatus();
  const { t } = useTranslation();

  const filtered_next_delivery_date =
    next_delivery_date === "unknown" ? "-" : next_delivery_date;
  const [quantity, setQuantity] = useState(max_quantity === 0 ? 0 : 1);

  useEffect(() => {
    setQuantity(max_quantity === 0 ? 0 : 1);
  }, [max_quantity]);

  const { showModal } = useModal();

  useEffect(() => {
    setQuantity(cart_quantity ? cart_quantity : 1);
  }, [cart_quantity]);

  return (
    <tr>
      <td style={{ maxWidth: "20rem" }}>
        <Flex sx={{ alignItems: "center" }}>
          <Text>{description}</Text>
          {image && (
            <Button
              variant="iconButton"
              sx={{
                marginLeft: "0.5rem",
                height: "1.5rem",
                minWidth: "1.5rem",
                width: "1.5rem",
              }}
            >
              <IconImage
                style={{ height: "1.125rem", width: "1.125rem" }}
                onClick={() =>
                  showModal(({ hideModal }) => {
                    return (
                      <ProductImageModal className="product-img-modal">
                        <Box className="product-img-container">
                          <img
                            className="img"
                            src={image}
                            onError={addDefaultSource}
                            alt={"hot deal"}
                          />
                        </Box>
                        <Button
                          className="icon-btn"
                          variant="secondaryIconButton"
                          onClick={() => hideModal()}
                        >
                          <IconClose />
                        </Button>
                      </ProductImageModal>
                    );
                  })
                }
              />
            </Button>
          )}
        </Flex>
      </td>
      <td>
        <tr>{ean}</tr>
        <tr
          style={{
            backgroundColor: themes.colors.transparent,
            fontStyle: "italic",
          }}
        >
          {part_number}
        </tr>
      </td>
      <td>
        <Flex sx={{ alignItems: "center", margin: "-0.5rem 0" }}>
          <span
            data-tip={`${
              stock_status === PRODUCT_STOCK_STATUS.AVAIBLE ||
              stock_status === PRODUCT_STOCK_STATUS.UPCOMING
                ? `${t("on_stock")}: ${actual_stock}<br/>${t(
                    "purchased"
                  )}: ${purchased_stock}<br/>${t(
                    "max_quantity"
                  )}: ${max_quantity}`
                : `${t("max_quantity")}: ${max_quantity}`
            }`}
            data-for={article_no}
            className={`status-indicator ${stock_status}`}
          >
            <ReactTooltip
              place={"bottom"}
              type={max_quantity === 0 ? "error" : "success"}
              id={article_no}
              multiline={true}
            />
          </span>
          <TableCounterInput
            value={quantity}
            maxValue={max_quantity}
            onChange={(value) => {
              setQuantity(value);
            }}
            telephone_order={tele_bestellen}
          />
          {tele_bestellen === TELE_BESTELLEN ? (
            <span>
              <ReactTooltip
                place={"bottom"}
                type={"warning"}
                id={`tel_${article_no}`}
                multiline={true}
              />

              <Button
                data-tip={t("contact_agent")}
                data-for={`tel_${article_no}`}
                variant="primaryIconButton"
                className="icon-button"
              >
                <IconPhone />
              </Button>
            </span>
          ) : is_added_to_cart ? (
            <Button
              variant="primaryIconButton"
              className="icon-button"
              disabled={quantity === cart_quantity || !isCOAEnabled}
              onClick={() => {
                onUpdateToCart(article_no, {
                  quantity,
                  total_amount: quantity * selling_price,
                  total_reward_points: quantity * reward_points,
                  csell: csell ? csell : false,
                });
              }}
            >
              <IconEdit />
            </Button>
          ) : (
            <Button
              variant="primaryIconButton"
              className="icon-button"
              disabled={max_quantity === 0 || !isCOAEnabled}
              onClick={() => {
                onAddToCart({
                  article_no,
                  ean,
                  description,
                  selling_price,
                  quantity,
                  max_quantity,
                  max_menge,
                  total_amount: quantity * selling_price,
                  reward_points,
                  total_reward_points: quantity * reward_points,
                  csell: csell ? csell : false,
                });
              }}
            >
              <IconPlus />
            </Button>
          )}
        </Flex>
      </td>
      <td>
        {stock_status === PRODUCT_STOCK_STATUS.AVAIBLE ||
        stock_status === PRODUCT_STOCK_STATUS.UPCOMING ? (
          <Text sx={{ fontSize: "1em", fontWeight: 700 }}>
            <Money amount={selling_price} />
          </Text>
        ) : (
          t("stock_status_not_available")
        )}
      </td>
      <td style={{ textAlign: "center" }}>
        <Flex style={{ alignItems: "center" }}>
          <span>{getFormatedYukaPoints(reward_points)}</span>
          <Image
            src={YPImage}
            style={{ height: "1rem", width: "auto", marginLeft: "0.25rem" }}
          />
        </Flex>
      </td>
      <td style={{ textAlign: "center" }}>
        <Flex style={{ alignItems: "center" }}>
          <span style={{ marginRight: "0.5rem" }}>
            {filtered_next_delivery_date}
          </span>
        </Flex>
      </td>
    </tr>
  );
};
ShopTableRow.propTypes = {
  data: PropTypes.shape({
    article_no: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    selling_price: PropTypes.number.isRequired,
    reward_points: PropTypes.number.isRequired,
    max_quantity: PropTypes.number.isRequired,
    next_delivery_date: PropTypes.string,
    stock_status: PropTypes.string.isRequired,
    is_added_to_cart: PropTypes.bool,
    cart_quantity: PropTypes.number,
  }),
  onAddToCart: PropTypes.func.isRequired,
  onUpdateToCart: PropTypes.func.isRequired,
};
export default ShopTableRow;
